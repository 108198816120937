@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/WorkSansBold.ttf");
  font-family: "Work Sans";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/WorkSansBlack.ttf");
  font-family: "Work Sans";
  font-weight: 900;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/WorkSansRegular.ttf");
  font-family: "Work Sans";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/WorkSansMedium.ttf");
  font-family: "Work Sans";
  font-weight: 500;
}
