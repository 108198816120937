@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*------Color variables------*/
    --black_900_19: #00000019;
    --black_900_66: #00000066;
    --blue_400: #359eff;
    --blue_600: #2b7fed;
    --blue_gray_50: #eff2f4;
    --blue_gray_500: #607289;
    --blue_gray_800: #3d4c5b;
    --gray_200: #e5e8ea;
    --gray_300: #dbe0e5;
    --gray_900: #141414;
    --gray_900_01: #111416;
    --white_a700: #ffffff;

    /*------Border radius variables------*/
    --radius-xs: 8px;
    --radius-sm: 12px;

    /*------Spacing variables------*/
    --space-xs: 8px;
    --space-sm: 10px;
    --space-md: 12px;
    --space-lg: 16px;
    --space-xl: 24px;
    --space-2xl: 32px;
    --space-3xl: 40px;
  }
}
@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[960px];
  }
}
